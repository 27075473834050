import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Text,
  Spinner,
  VStack,
  Stat,
  Center,
  Image,
  HStack,
  IconButton,
  Tooltip,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  RepeatIcon,
} from "@chakra-ui/icons"; // Import RepeatIcon for reload
import { ethers } from "ethers";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";
import WithdrawalModal from "./WithdrawalModal";
import UsdcBalanceFetcher from "./UsdBalanceFetcher";
import SwapModal2 from "./SwapModal2";

//import LiquidityInfo from "./LiquidityInfo";

const providerUrl = "https://arb1.arbitrum.io/rpc";
const link = "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4";
const weth = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
const usdc = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
const btc = "0x152b9d0FdC40C096757F570A51E494bd4b943E50";
const usdc_e = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
const wbtc = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";

const TokenValue = ({
  totalCollateral,
  totalPositions,
  onActiveBalanceUpdate,
  reloadBalances,
}) => {
  const [tokenValues, setTokenValues] = useState(null);
  const toast = useToast();
  const [utilizationRate, setUtilizationRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const { rpcUrl, account, contractAddress } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [showZeroBalance, setShowZeroBalance] = useState(true); // State to toggle zero balance tokens
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  // Functions to open each modal
  const [isSwapModalOpen, setSwapModalOpen] = useState(false);

  const openSwapModal = () => setSwapModalOpen(true);
  const closeSwapModal = () => setSwapModalOpen(false);
  // Modal open/close handlers
  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);
  const [isWithdrawalModalOpen, setUSDCEModalOpen] = useState(false);
  const openWithdrawalModal = () => setUSDCEModalOpen(true);
  const closeWithdrawalModal = () => setUSDCEModalOpen(false);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const provider = new ethers.providers.JsonRpcProvider(providerUrl);
  const ERC20ABI = ["function balanceOf(address owner) view returns (uint256)"];

  const usdcUSD = 1;

  const usdcBalanceRef = useRef(null);

  const handleUsdcBalanceChange = (balance) => {
    console.log("USDC Balance:", balance);
    setUsdcBalance(balance);
  };

  const reloadUsdcBalance = () => {
    if (usdcBalanceRef.current) {
      usdcBalanceRef.current.reloadBalance();
    }
  };

  function truncateToTwoDecimals(num) {
    return Math.trunc(num * 100) / 100;
  }

  function truncateToFourDecimals(num) {
    return Math.trunc(num * 10000) / 10000;
  }

  const fetchBtcPrice = async () => {
    try {
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const acceptablePrice = await routerContract.getPriceV1(btc, false, true);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      return priceInWei.div(scaleFactor).toNumber() / 1000;
    } catch (error) {
      console.error("Error fetching BTC price:", error);
      throw error;
    }
  };

  const fetchWethPrice = async () => {
    try {
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const acceptablePrice = await routerContract.getPriceV1(
        weth,
        false,
        true
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      return priceInWei.div(scaleFactor).toNumber() / 1000;
    } catch (error) {
      console.error("Error fetching WETH price:", error);
      throw error;
    }
  };

  const fetchLinkPrice = async () => {
    try {
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const acceptablePrice = await routerContract.getPriceV1(
        link,
        false,
        true
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      return priceInWei.div(scaleFactor).toNumber() / 1000;
    } catch (error) {
      console.error("Error fetching LINK price:", error);
      throw error;
    }
  };

  

  const fetchTokenValue = async () => {
    setLoading(true); // Set loading state to true when reloading
    try {
      const ethBalance = await provider.getBalance(contractAddress);
      const ethBal = truncateToTwoDecimals(
        parseFloat(ethers.utils.formatEther(ethBalance))
      );

      const wethContract = new ethers.Contract(weth, ERC20ABI, provider);
      const wethBalance = await wethContract.balanceOf(contractAddress);
      const wethBal = truncateToFourDecimals(
        parseFloat(ethers.utils.formatEther(wethBalance))
      );

    //  const btcContract = new ethers.Contract(btc, ERC20ABI, provider);
    //  const btcBalance = await btcContract.balanceOf(contractAddress);
    //  const btcBalNumber = parseFloat(ethers.utils.formatUnits(btcBalance, 8));

      const wbtcContract = new ethers.Contract(wbtc, ERC20ABI, provider); // WBTC contract instance
      const wbtcBalance = await wbtcContract.balanceOf(contractAddress);
      const wbtcBalNumber = parseFloat(
        ethers.utils.formatUnits(wbtcBalance, 8)
      );

      const usdcContract = new ethers.Contract(usdc, ERC20ABI, provider);
      const usdcBalance = await usdcContract.balanceOf(contractAddress);
      const usdcBal = truncateToTwoDecimals(
        parseFloat(ethers.utils.formatUnits(usdcBalance, 6))
      );

      const usdcEContract = new ethers.Contract(usdc_e, ERC20ABI, provider);
      const usdcEBalance = await usdcEContract.balanceOf(contractAddress);
      const usdcEBal = truncateToTwoDecimals(
        parseFloat(ethers.utils.formatUnits(usdcEBalance, 6))
      );

      const linkContract = new ethers.Contract(link, ERC20ABI, provider);
      const linkBalance = await linkContract.balanceOf(contractAddress);
      const linkBal = truncateToTwoDecimals(
        parseFloat(ethers.utils.formatUnits(linkBalance, 18))
      );

  //    const btcPrice = await fetchBtcPrice();
      const wethPrice = await fetchWethPrice();
      const linkPrice = await fetchLinkPrice();

  //    const btcUSD = btcBalNumber * btcPrice;
  //    const wbtcUSD = wbtcBalNumber * btcPrice; // Calculate WBTC in USD
      const wethUSD = parseFloat(wethPrice) * wethBal || 0;
      const ethUSD = parseFloat(wethPrice) * ethBal || 0;
      const linkUSD = parseFloat(linkPrice) * linkBal || 0;

      const tokenValue = {
        balances: {
          usdc: { balance: usdcBal, usdValue: usdcBal * usdcUSD },
          usdc_e: { balance: usdcEBal, usdValue: usdcEBal * usdcUSD },
          eth: { balance: ethBal, usdValue: ethUSD },
          link: { balance: linkBal, usdValue: linkUSD },
          weth: { balance: wethBal, usdValue: wethUSD },
       //   btc: { balance: btcBalNumber, usdValue: btcUSD },
      //    wbtc: { balance: wbtcBalNumber, usdValue: wbtcUSD }, // Add WBTC balance and USD value
        },
      };

      setTokenValues(tokenValue);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching account data:", error);
      setError(error);
      setLoading(false);
    }
  };



  // Trigger fetchTokenValue whenever reloadBalances changes
useEffect(() => {
  
    fetchTokenValue();
    reloadUsdcBalance();
    toast({
      title: "Refreshing",
      //description: "Latest balances fetched successfully.",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom-right",
    });
  
}, [reloadBalances, toast]);


  // Function to reload all data, including USDC balance, positions, and total collateral
  const handleReload = async () => {
    // reload collateral utilization rate
    calculateCollateralUtilizationRate();
    // Reload positions
    //  handleGetPositions();
    // Reload the USDC balance by triggering the UsdcBalanceFetcher component
    reloadUsdcBalance();
    fetchTokenValue();
    //reloadPrice();
    toast({
      title: "Reloading",
      description: "Fetching latest data...",
      status: "info",
      duration: 1000,
      isClosable: true,
      position: "bottom",
    });
  };

  // Function to calculate and set collateral utilization rate and total collateral
  const calculateCollateralUtilizationRate = () => {
    const tb = parseFloat(usdcBalance) + parseFloat(totalCollateral);
    // setTotalBalance(tb.toFixed(2));

    if (tb > 0) {
      const utilization = (parseFloat(totalCollateral) / tb) * 100;
      setUtilizationRate(utilization.toFixed(2));
    } else {
      setUtilizationRate(0);
    }
  };

  // Update collateral utilization whenever `usdcBalance`, `totalCollateral`, or `handleReload` changes
  useEffect(() => {
    calculateCollateralUtilizationRate();
  }, [usdcBalance, totalCollateral, handleReload]);

  const totalBalance = tokenValues?.balances
  ? Object.values(tokenValues.balances)
      .reduce((acc, token) => acc + token.usdValue, 0)
      .toFixed(2)
  : 0; // Default to 0 if tokenValues.balances is undefined or empty

  const activeBalance = parseFloat(totalBalance) + parseFloat(totalCollateral);
    // Pass activeBalance to the parent whenever it changes
    useEffect(() => {
      onActiveBalanceUpdate(activeBalance);
    }, [activeBalance, onActiveBalanceUpdate]);
  if (loading) {
    return (
      <VStack justify="center" w="auto" mt={6}>
        <Spinner size="sm" textAlign={"center"} />
        <Text w="auto" fontSize="sm" textAlign={"center"} color="green">
          Loading Balance
        </Text>
      </VStack>
    );
  }

  if (error) {
    return (
      <Center>
        <Text color="red.500">Error fetching data: {error.message}</Text>
      </Center>
    );
  }

  const tokenImages = {
    eth: "./eth.png",
    usdc: "./usdc.png",
    usdc_e: "./usdc.png", // Assuming USDC.e uses the same image as USDC
    link: "./chainlink.png",
    weth: "./weth.png", // 
    btc: "./btc.png",
    wbtc: "./wbtc.png",
  };

  //const totalBalance = tokenValues.balances.usdc_e.usdValue + totalCollateral;
  // Calculate the total balance of all tokens in USD


  return (
    <Box  h='100%' >
      {/**<VStack>
        <HStack justify="center" mb={1} gap={4}>
          <Text w="100%" textAlign="center" fontSize="13px" as="b" mt={1}>
            Balance: ${totalBalance}
          </Text>
          
          <Tooltip
            label="Reload Balances"
            aria-label="reload"
            placement="top" // Tooltip position relative to the button
            hasArrow // Optional: Adds an arrow pointing to the button
          >
            <IconButton
              aria-label="Reload Balances"
              icon={<RepeatIcon />}
              colorScheme="blue"
              size="xs"
              variant="unstyled"
              onClick={handleReload}
            />
          </Tooltip>
        </HStack>
        {/**<Text w="100%" textAlign="center" fontSize="13px" as="b" mt={1}>
          Total: ${activeBalance}
        </Text> 
      </VStack> */}

      {/**<Text>Total Collateral: ${totalCollateral}</Text></Box> */}

      {Object.entries(tokenValues.balances)
        .filter(([token, { usdValue }]) => usdValue > 0.9 || showZeroBalance)
        .map(([token, { balance, usdValue }]) => (
          <Stat key={token}>
            <HStack bg='gray.50' h={'auto'} justify="left"   borderBottom='1px solid silver'>
              {/* Token Image and Name */}
              <HStack gap="2px" mr={1} >
                <Image
                ml={2}
                  width={4}
                  height={4}
                  src={tokenImages[token]}
                  alt={`${token} logo`}
                />
                <Text fontSize='sm'>{token.toUpperCase()}</Text>
              </HStack>
              <Spacer />

              {/* Balance and USD Value */}
              <HStack w='100%'  fontSize='sm' justify='right'>
                <Text>{(balance || 0).toFixed(3)}</Text>
                <Text>(${(usdValue || 0).toFixed(2)})</Text>
              </HStack>
              <Spacer />
              {/* Withdraw and Swap Actions */}
              <HStack justify="right"  p={0}>
                <Tooltip
                  label="Withdraw Tokens"
                  aria-label="Withdraw Tooltip"
                  placement="top"
                  hasArrow
                >
                  <IconButton
                    aria-label="Withdraw Details"
                    icon={<ArrowUpIcon />}
                    onClick={openWithdrawalModal}
                    variant="unstyled"
                    fontSize="16px"
                    mt={-1}
                    colorScheme="blue"
                    size="md"
                  />
                </Tooltip>
                <SwapModal2 />
              </HStack>
            </HStack>
          </Stat>
        ))}

      <Box w="100%" textAlign="right" float="right" >
      <HStack mb={2} gap='2px' mt={1}>
      {!showZeroBalance && (
        <Text pr={1} fontSize='xx-small' textAlign='right' w='100%'>
          Expand
        </Text>
      )}
      {showZeroBalance && (
        <Text pr={1} fontSize='xx-small' textAlign='right' w='100%'>
        Hide
        </Text>
      )}
        <Tooltip
          label={
            showZeroBalance
              ? "Hide Low Balance Tokens"
              : "Show Low Balance Tokens"
          } // Tooltip text
          aria-label="A tooltip"
          placement="top" // Tooltip position relative to the button
          hasArrow // Optional: Adds an arrow pointing to the button
        >
        
          <IconButton
            variant={"outline"}
            //border="1px solid #459fff"
            size="xs"
            icon={showZeroBalance ? <ChevronUpIcon /> : <ChevronDownIcon />}
            aria-label="Toggle zero balance tokens"
            onClick={() => setShowZeroBalance(!showZeroBalance)}
          />
        </Tooltip>
      </HStack>
      </Box>
      {/**   <AvaxDetailsModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        tokenValues={tokenValues}
        dailyTotal={dailyTotal}
        dailyLimit={dailyLimit}
      /> */}

      <WithdrawalModal
        isOpen={isWithdrawalModalOpen}
        onClose={closeWithdrawalModal}
      />

      {/* Fetch and display USDC balance */}
      <UsdcBalanceFetcher onUsdcBalance={handleUsdcBalanceChange} />
    </Box>
  );
};

export default TokenValue;
