import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  Text,
  Image,
  Box,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaGasPump } from "react-icons/fa";
import TransferAvaxToPaymaster from "./TransferAvaxToPaymaster";
import PaymasterLimits from "./PaymasterLimits";

const AvaxDetailsModal2 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Transfer ETH to Paymaster" placement="top">
        <IconButton
          onClick={onOpen}
          icon={<FaGasPump />}
          aria-label="Refill ETH"
          variant="outline"
          colorScheme="blue"
      w="10px"
      size="sm"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack mb={-1}>
              <Image width={5} src="./weth.png" alt="eth Logo" />
              <Text>Paymaster Details</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={1}>
              <PaymasterLimits />
              <TransferAvaxToPaymaster />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AvaxDetailsModal2;
