import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  VStack,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import CreateWallet from "./CreateWalletArb";
import DeployedWallets from "./DeployedWallets";
import SignalsSection from "../SignalsSection";
import VideoPlayer from "../VideoPlayer";

const WalletTabs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tabs size="md" variant="unstyled" mt={1} h='100vh' bg='black'>
        <TabList p={0}  bg="#0051ff" color="white">
          <Tab fontSize='sm'>Home</Tab>
          <Tab fontSize='sm'>Create</Tab>
          {/**<Tab>Load</Tab> */}
          <Button mt={1} ml="auto" mr={0.5}  size="sm" onClick={onOpen}>
            View Accounts
          </Button>
        </TabList>

        <TabPanels>
          <TabPanel p={1} w="100%" bg="black" h='100%'>
            <VStack justify="center" p={4}>
              <VideoPlayer />
             
            </VStack>
          </TabPanel>

          <TabPanel p={0} bg='black'>
            <Center><Box p={4} maxWidth="400px">
              <CreateWallet />
            </Box></Center>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Right Sliding Drawer for DeployedWallets */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          
          <DrawerBody p={1} mt={3}>
            <DeployedWallets />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default WalletTabs;
