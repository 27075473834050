import React, { useEffect, useContext } from "react";
import { ethers } from "ethers";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";

const FetchWavaxPriceNoReturn = ({ onPriceFetched }) => {
  const { provider } = useContext(AppContext);
  const weth = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

  useEffect(() => {
    const fetchWavaxPrice = async () => {
      try {
        // Initialize routerContract with the VaultPriceFeed contract
        const routerContract = new ethers.Contract(
          VaultPriceFeed.address, // Ensure this address is correctly defined in your VaultPriceFeed.json
          VaultPriceFeed.abi,
          provider
        );

        // Fetch the price from the contract
        const acceptablePrice = await routerContract.getPriceV1(weth, false, true);
        const scaleFactor = ethers.BigNumber.from("10").pow(27);
        const priceInWei = ethers.BigNumber.from(acceptablePrice);
        const currentWavaxPrice = priceInWei.div(scaleFactor).toNumber() / 1000;

        // Call the callback function to return the price to the parent
        onPriceFetched(currentWavaxPrice);
      } catch (error) {
        console.error("Error fetching WAVAX price:", error);
        onPriceFetched(null); // Optionally handle error by passing null or an error message
      }
    };

    fetchWavaxPrice();
  }, [provider, onPriceFetched]);

  // No return statement, as this component does not render anything
  return null;
};

export default FetchWavaxPriceNoReturn;
