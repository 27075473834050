import React from "react";
import { Box, Text, Card, CardHeader, CardBody, List, ListItem, ListIcon } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import OptimalTradeConfig from "./OptimalTradeConfig";

const OptimalTradeConfigDescription = () => {
  return (
    <Box maxW="800px" mx="auto" mt={5}>
      <Card borderRadius="2xl" boxShadow="lg" p={5}>
        <CardHeader>
          <Text fontSize="2xl" fontWeight="bold" >
            Optimal Trade Config API
          </Text>

        </CardHeader>
        <CardBody>
          <Text fontSize="md" mb={4}>
            The **Optimal Trade Config API** provides real-time trading parameters to help users optimize their leverage, 
            collateral utilization, and position ratios for efficient risk management. This API dynamically adjusts values 
            based on market conditions to enhance trading strategies.
          </Text>
          <Text fontSize="lg" fontWeight="semibold"  mb={2}>
            Key Features:
          </Text>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Dynamic Leverage Suggestions** – Get recommended long and short leverage values.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Collateral Utilization** – Understand how much of your balance is allocated.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Position Ratios** – Monitor optimal long and short position ratios.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Timestamped Data** – Stay updated with real-time market adjustments.
            </ListItem>
          </List>
          <OptimalTradeConfig/>
        </CardBody>
      </Card>
    </Box>
  );
};

export default OptimalTradeConfigDescription;
