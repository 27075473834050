import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spinner,
  Button,
  Flex,
  Text,
  HStack,
  Stack,
  useToast,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";

const OptimalTradeConfig = () => {
  const [data, setData] = useState(null); // Store only the most recent entry
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  /**
   * Fetch the most recent optimal trade config data
   */
  const fetchOptimalTradeConfig = async () => {
    setLoading(true);
    try {
      const apiUrl = "https://blindfoldcapital.com/api/ta/optimal_trade_config";
      const response = await axios.get(apiUrl);
  
      if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        // Get the last entry in the array (most recent data)
        const mostRecentData = response.data.data[response.data.data.length - 1];
  
        setData(mostRecentData);
      } else {
        throw new Error("Invalid API response format");
      }
    } catch (error) {
      toast({
        title: "Error fetching trade config",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  

  /**
   * Auto-refresh every 2 minutes
   */
  useEffect(() => {
    fetchOptimalTradeConfig();
    const interval = setInterval(fetchOptimalTradeConfig, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box p={4}>
      <Flex mb={4} justify="space-between" align="center">
        <Heading size="sm">
          <HStack>
            <Image src="/blindfold-logo-no-text.png" w={"25px"} />
            <Text>Optimal Trade Configuration</Text>
          </HStack>
        </Heading>
        <Button colorScheme="blue" size="sm" onClick={fetchOptimalTradeConfig}>
          Refresh
        </Button>
      </Flex>

      {loading ? (
        <Flex direction="column" align="center" justify="center">
          <Spinner size="lg" mb={2} />
          <Text>Refreshing data...</Text>
        </Flex>
      ) : data ? (
        <>
          {isMobile ? (
            <Stack spacing={4}>
              <Box
                borderWidth="1px"
                borderRadius="md"
                p={4}
                bg="gray.50"
                shadow="md"
              >
                <HStack justify="space-between">
                  <Text>
                    <strong>Long Leverage:</strong> {data.long_leverage}
                  </Text>
                  <Text>
                    <strong>Short Leverage:</strong> {data.short_leverage}
                  </Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>
                    <strong>Collateral Utilization:</strong>{" "}
                    {data.collateral_utilization}
                  </Text>
                  <Text>
                    <strong>Long Ratio:</strong> {data.long_ratio}
                  </Text>
                </HStack>
                <HStack justify="space-between">
                  <Text>
                    <strong>Short Ratio:</strong> {data.short_ratio}
                  </Text>
                  <Text>
                    <strong>Timestamp:</strong> {data.timestamp}
                  </Text>
                </HStack>
              </Box>
            </Stack>
          ) : (
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th>Long Leverage</Th>
                  <Th>Short Leverage</Th>
                  <Th>Collateral Utilization</Th>
                  <Th>Long Ratio</Th>
                  <Th>Short Ratio</Th>
                  <Th>Timestamp</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{data.long_leverage}</Td>
                  <Td>{data.short_leverage}</Td>
                  <Td>{data.collateral_utilization}</Td>
                  <Td>{data.long_ratio}</Td>
                  <Td>{data.short_ratio}</Td>
                  <Td>{data.timestamp}</Td>
                </Tr>
              </Tbody>
            </Table>
          )}
        </>
      ) : (
        <Text>No data available</Text>
      )}
    </Box>
  );
};

export default OptimalTradeConfig;
