import { Heading, HStack, Link, Text } from "@chakra-ui/react";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";



const ContractInfo = ({ contractName, activeContractAddress, approvalWindowStatus }) => {
  return (
    <>
      <Heading size="sm" textAlign="center" color='gray.500'>
        {contractName}
      </Heading>
      <Heading fontSize="sm"  borderRadius={6} color='gray.500' textAlign={"center"}>
        <HStack justify='center'>
        <Link
          target="_blank"
          href={`https://arbiscan.io/address/${activeContractAddress}/`}
        >
          {formatAddress(activeContractAddress)}
        </Link>
        <CopyToClipboardButton value={activeContractAddress} />
        </HStack>
      </Heading>
      <Text>{approvalWindowStatus}</Text>
    </>
  );
};

export default ContractInfo;
