import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Button,
  Input,
  Text,
  VStack,
  Select,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import Reader from "../../contracts/Reader.json";
import JakobWallet from "../../contracts/JakobWallet.json";
import { CloseIcon } from "@chakra-ui/icons";
import { AppContext } from "../../AppContext";

const SwapTokens = () => {
  const [token1, setToken1] = useState("");
  const [token2, setToken2] = useState("");
  const [amountIn, setAmountIn] = useState("");
  const [minOut, setMinOut] = useState("");
  const { rpcUrl, account, contractAddress } = useContext(AppContext);
  const [status, setStatus] = useState("");

  const readerAddress = "0x22199a49A999c351eF7927602CFB187ec3cae489";
  const vaultAddress = "0x489ee077994B6658eAfA855C308275EAd8097C4A";
  const routerAddress = "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064";

  const tokens = {
    eth: "0x0000000000000000000000000000000000000000",
    weth: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    link: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
    usdc: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    usdc_e: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8", // USDC.e
    btc: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
  };

  const tokenDecimals = {
    "0x0000000000000000000000000000000000000000": 18, // AVAX
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB": 18, // WETH
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7": 18, // WAVAX
    "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E": 6, // USDC
    "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664": 6, // USDC.e
    "0x152b9d0FdC40C096757F570A51E494bd4b943E50": 8, // BTC
  };

  const tokenNames = {
    "0x0000000000000000000000000000000000000000": "AVAX",
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB": "WETH",
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7": "WAVAX",
    "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E": "USDC",
    "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664": "USDC.e",
    "0x152b9d0FdC40C096757F570A51E494bd4b943E50": "BTC",
  };

  const handleToken1Selection = (event) => {
    setToken1(event.target.value);
    console.log(`Token 1 selected: ${event.target.value}`);
  };

  const handleToken2Selection = (event) => {
    setToken2(event.target.value);
    console.log(`Token 2 selected: ${event.target.value}`);
  };

  const handleSwap = async () => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({ method: "eth_requestAccounts" });
  
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
  
      // Check that token1 and token2 are selected
      if (!token1 || !token2) {
        setStatus("Please select both tokens");
        return;
      }
  
      // Validate amountIn
      if (!amountIn || isNaN(amountIn) || parseFloat(amountIn) <= 0) {
        setStatus("Invalid amount");
        return;
      }
  
      // Convert amountIn to the correct decimals based on token1
      const amountInWei = ethers.utils.parseUnits(
        amountIn.toString(),
        tokenDecimals[token1]
      );
  
      const JakobWalletContract = new ethers.Contract(
        contractAddress,
        JakobWallet.abi,
        signer
      );
  
      
        // Normal swap logic
        const token1Contract = new ethers.Contract(
          token1,
          [
            "function approve(address spender, uint256 amount) public returns (bool)",
          ],
          signer
        );
  
        // Approve the router to spend token1
        const approvalTx = await token1Contract.approve(
          routerAddress,
          amountInWei
        );
        await approvalTx.wait();
  
        // Convert minOut to the correct decimals based on token2
        const minOutWei = ethers.utils.parseUnits(
          minOut.toString(),
          tokenDecimals[token2]
        );
  
        // Check if we are swapping from WAVAX to AVAX
      if (token2 === tokens.link) {
        // Unwrap WAVAX to AVAX
        const tx = await JakobWalletContract.swapTokensToETH([token1, token2],amountInWei,minOutWei);
        await tx.wait();
        setStatus(`Successful Swap to Native AVAX `, tx.receipt);
      } else {
        // Perform the swap
        const tx = await JakobWalletContract.swapTokens(
          [token1, token2], // Path
          amountInWei, // Amount in (Wei)
          minOutWei // Minimum out (Wei)
          //contractAddress
        );
  
        await tx.wait();
        setStatus("Swap successful!");
      }
    } catch (err) {
      console.error("Swap error:", err);
      if (err.data && err.data.message.includes("poolAmount < buffer")) {
        setStatus(
          "Error: Insufficient liquidity in the vault for this swap amount. Try reducing the swap amount."
        );
      } else {
        setStatus(`Error: ${err.message}`);
      }
    }
  };
  

  const handleGetAmountOut = async () => {
    try {
      // Ensure token1 and token2 are selected before proceeding
      if (!token1 || !token2) {
        console.log("Token1 or Token2 is not selected yet.");
        return;
      }

      // Log token addresses and token decimals for debugging
      console.log(`Token1: ${token1}, Token2: ${token2}`);
      console.log(`Decimals for token1: ${tokenDecimals[token1]}, Decimals for token2: ${tokenDecimals[token2]}`);

      // Ensure the token decimals are defined
      const tokenInDecimals = tokenDecimals[token1];
      const tokenOutDecimals = tokenDecimals[token2];

      if (!tokenInDecimals || !tokenOutDecimals) {
        throw new Error(
          `Invalid token decimals for token1: ${token1} or token2: ${token2}`
        );
      }

      const provider = new ethers.providers.JsonRpcProvider(
        "https://api.eth.network/ext/bc/C/rpc"
      );
      const contract = new ethers.Contract(readerAddress, Reader.abi, provider);
      const vault = ethers.utils.getAddress(vaultAddress);
      const tokenIn = ethers.utils.getAddress(token1);
      const tokenOut = ethers.utils.getAddress(token2);

      // Convert amountIn to Wei based on token1's decimals
      const amountInWei = ethers.utils.parseUnits(
        amountIn.toString(),
        tokenInDecimals
      );

      const [amountOutWei] = await contract.getAmountOut(
        vault,
        tokenIn,
        tokenOut,
        amountInWei
      );

      // Format amountOut based on token2's decimals
      const formattedAmountOut = ethers.utils.formatUnits(
        amountOutWei,
        tokenOutDecimals
      );

      setMinOut(parseFloat(formattedAmountOut).toFixed(5)); // Fix to 5 decimal places
    } catch (err) {
      console.error("Error in handleGetAmountOut:", err);
    }
  };

  useEffect(() => {
    if (token1 && token2 && amountIn) {
      handleGetAmountOut();
    }
  }, [token1, token2, amountIn]);

  const clearToken1Inputs = () => {
    setToken1("");
    setAmountIn("");
    setMinOut("");
    setStatus("");
  };

  const clearToken2Inputs = () => {
    setToken2("");
    setMinOut("");
    setStatus("");
  };

  return (
    <VStack
      spacing={4}
      p={4}
      bg="ghostwhite"
      border="2px solid #459fff"
      borderRadius={6}
    >
      <HStack>
        <Text fontSize="md" fontWeight="bold" mt={1}>
         GMX v1 Swap
        </Text>
      </HStack>

      <HStack>
        <Select
          bg="white"
          border="1px solid blue"
          placeholder="Select Token 1"
          value={token1}
          onChange={handleToken1Selection}
        >
          <option value={tokens.eth}>AVAX</option>
          <option value={tokens.weth}>WETH</option>
          <option value={tokens.link}>WAVAX</option>
          <option value={tokens.usdc}>USDC</option>
          <option value={tokens.usdc_e}>USDC.e</option>
        </Select>

        <Input
          bg="white"
          border="1px solid blue"
          w="80px"
          placeholder="Amt."
          value={amountIn}
          onChange={(e) => setAmountIn(e.target.value)}
        />

        <IconButton
          icon={<CloseIcon />}
          aria-label="Clear"
          onClick={clearToken1Inputs}
          size="sm"
          variant="outline"
        />
      </HStack>

      <HStack>
        <Select
          bg="white"
          border="1px solid blue"
          placeholder="Select Token 2"
          value={token2}
          onChange={handleToken2Selection}
        >
          <option value={tokens.eth}>AVAX</option>
          <option value={tokens.weth}>WETH</option>
          <option value={tokens.link}>WAVAX</option>
          <option value={tokens.usdc}>USDC</option>
          <option value={tokens.usdc_e}>USDC.e</option> {/* Added USDC.e */}
          <option value={tokens.btc}>BTC</option>
        </Select>
        <Input
          bg="white"
          border="1px solid blue"
          w="80px"
          placeholder="Amt."
          value={minOut}
          onChange={(e) => setMinOut(e.target.value)}
        />

        <IconButton
          icon={<CloseIcon />}
          aria-label="Clear"
          onClick={clearToken2Inputs}
          size="sm"
          variant="outline"
        />
      </HStack>

      <Button colorScheme="blue" w="100%" onClick={handleSwap}>
        Swap
      </Button>

      {minOut && token2 && (
        <Text>
          Receive: {minOut} {tokenNames[token2]}
        </Text>
      )}

      {status && (
        <Text overflow={"auto"} noOfLines={3} maxWidth={200}>
          Status: {status}
        </Text>
      )}
    </VStack>
  );
};

export default SwapTokens;
