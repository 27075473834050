import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Box,
  Center,
  HStack,
  Spinner,
  Text,
  VStack,
  Button,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { AppContext } from "../../AppContext";
import JakobFactory from "../../contracts/JakobFactory.json";
import JakobWallet from "../../contracts/JakobWallet.json";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";

const DeployedWallets = () => {
  const [deployedWallets, setDeployedWallets] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const { rpcUrl, setContractAddress } = useContext(AppContext);
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const walletFactoryContract = new ethers.Contract(
    JakobFactory.address,
    JakobFactory.abi,
    provider,
  );

  const USDC_E_ADDRESS = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
  const USDC_ADDRESS = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
  const ETH_ADDRESS = "0x0000000000000000000000000000000000000000";
  const LINK_ADDRESS = "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4";
  const UNI_ADDRESS = "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0";
  const BTC_ADDRESS = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
  const WETH_ADDRESS = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

  const fetchNativeBalance = async (walletAddress) => {
    const balance = await provider.getBalance(walletAddress);
    return ethers.utils.formatUnits(balance, 18);
  };

  const fetchTokenBalance = async (tokenAddress, walletAddress) => {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ["function balanceOf(address owner) view returns (uint256)"],
      provider,
    );
    const balance = await tokenContract.balanceOf(walletAddress);
    return ethers.utils.formatUnits(
      balance,
      tokenAddress === USDC_ADDRESS ? 6 : tokenAddress === BTC_ADDRESS ? 8 : 18,
    );
  };

  const fetchOwnersFromWallet = async (walletAddress) => {
    const walletContract = new ethers.Contract(
      walletAddress,
      JakobWallet.abi,
      provider,
    );
    const [owner1, owner2, owner3, paymaster] = await Promise.all([
      walletContract.owner1(),
      walletContract.owner2(),
      walletContract.owner3(),
      walletContract.paymaster(),
    ]);
    return { owner1, owner2, owner3, paymaster };
  };

  const fetchDeployedWallets = async () => {
    try {
      setLoading(true);
      const wallets = await walletFactoryContract.getDeployedWallets();

      const updatedWallets = await Promise.all(
        wallets.map(async (wallet) => {
          const nativeBalance = await fetchNativeBalance(wallet.walletAddress);
          const usdcBalance = await fetchTokenBalance(
            USDC_ADDRESS,
            wallet.walletAddress,
          );
          const linkBalance = await fetchTokenBalance(
            LINK_ADDRESS,
            wallet.walletAddress,
          );
          const btcBalance = await fetchTokenBalance(
            BTC_ADDRESS,
            wallet.walletAddress,
          );
          const wethBalance = await fetchTokenBalance(
            WETH_ADDRESS,
            wallet.walletAddress,
          );
          const owners = await fetchOwnersFromWallet(wallet.walletAddress);

          return {
            ...wallet,
            ...owners,
            nativeBalance,
            usdcBalance,
            linkBalance,
            btcBalance,
            wethBalance,
          };
        }),
      );

      setDeployedWallets(updatedWallets);
    } catch (error) {
      console.error("Error fetching deployed wallets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeployedWallets();
  }, []);

  // Toggle the visibility of details for a specific wallet
  const toggleDetails = (index) => {
    setDeployedWallets((prevWallets) =>
      prevWallets.map((wallet, i) =>
        i === index ? { ...wallet, showDetails: !wallet.showDetails } : wallet,
      ),
    );
  };

  return (
    <Box fontFamily="Sentinel">
      <Text mb={2} fontWeight="bold">
        Arbitrum Accounts
      </Text>

      {loading ? (
        <Center mt={8}>
          <VStack>
            <Spinner />
            <Text>Loading accounts...</Text>
          </VStack>
        </Center>
      ) : deployedWallets.length > 0 ? (
        deployedWallets.map((wallet, index) => {
          return (
            <Box
              key={index}
              p={2}
              w="100%"
              shadow="md"
              borderWidth="1px"
              //borderRadius="md"
              mt={3}
              mb={2}
            >
              <HStack gap="auto" mb={1}>
                <HStack overflow="hidden" gap="1px">
                  <Text as="b" overflow="hidden">
                    {wallet.contractName}
                  </Text>
                  <Text ml={1} fontSize="sm" textAlign="right">
                    <a
                      href={`https://arbiscan.io/address/${wallet.walletAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatAddress(wallet.walletAddress)}
                    </a>
                  </Text>
                  <CopyToClipboardButton value={wallet.walletAddress} />
                </HStack>
                <Text ml={1} fontSize="sm">

                        
                        ${parseFloat(wallet.usdcBalance).toFixed(2)} USDC
                      </Text>
                <Spacer />
                {/* Toggle Details IconButton */}
                {/* WalletDetails component displayed conditionally */}
                {/* Toggle Details IconButton */}
                <IconButton
                  icon={
                    wallet.showDetails ? <ChevronUpIcon /> : <ChevronDownIcon />
                  }
                  aria-label="Toggle Details"
                  onClick={() => toggleDetails(index)}
                  //mb={2}
                  ml={1}
                  variant="outline"
                  size="xs"
                  colorScheme="blue"
                />
              </HStack>
              <Box>
                {/* IconButton to toggle details */}

                {/* Details section */}
                {!wallet.showDetails && (
                  <VStack align="start" spacing={2}>
                    {/**<Text fontSize='sm'>Type: {wallet.category}</Text> */}
                    <HStack>
                      <Text>
                        <strong>Owner1:</strong>{" "}
                        <a
                          href={`https://arbiscan.io/address/${wallet.owner1}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner1)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner1} />
                    </HStack>

                    <HStack>
                      <Text>
                        <strong>Owner2:</strong>{" "}
                        <a
                          href={`https://arbiscan.io/address/${wallet.owner2}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner2)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner2} />
                    </HStack>

                    <HStack>
                      <Text>
                        <strong>Owner3:</strong>{" "}
                        <a
                          href={`https://arbiscan.io/address/${wallet.owner3}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.owner3)}
                        </a>
                      </Text>
                      <Spacer />
                      <CopyToClipboardButton value={wallet.owner3} />
                    </HStack>

                    <HStack>
                      <Text mr={1}>
                        <strong>Spender:</strong>{" "}
                        <a
                          href={`https://arbiscan.io/address/${wallet.paymaster}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(wallet.paymaster)}
                        </a>
                      </Text>

                      <CopyToClipboardButton value={wallet.paymaster} />
                    </HStack>
                    {parseFloat(wallet.nativeBalance) > 0 && (
                      <Text>
                        <strong>ETH Balance:</strong>{" "}
                        {parseFloat(wallet.nativeBalance).toFixed(5)} ETH
                      </Text>
                    )}
                    {parseFloat(wallet.usdcBalance) > 0 && (
                      <Text>
                        <strong>USDC Balance:</strong>{" "}
                        {parseFloat(wallet.usdcBalance).toFixed(2)} USDC
                      </Text>
                    )}
                    {parseFloat(wallet.linkBalance) > 0 && (
                      <Text>
                        <strong>LINK Balance:</strong>{" "}
                        {parseFloat(wallet.linkBalance).toFixed(3)} LINK
                      </Text>
                    )}
                    {parseFloat(wallet.btcBalance) > 0 && (
                      <Text>
                        <strong>BTC Balance:</strong>{" "}
                        {parseFloat(wallet.btcBalance).toFixed(5)} BTC
                      </Text>
                    )}
                    {parseFloat(wallet.wethBalance) > 0 && (
                      <Text>
                        <strong>WETH Balance:</strong>{" "}
                        {parseFloat(wallet.wethBalance).toFixed(5)} WETH
                      </Text>
                    )}
                  </VStack>
                )}
              </Box>
              {/* Button to select this wallet */}
              <Button
                colorScheme="blue"
                size="xs"
                borderRadius={0}
                w="100%"
                onClick={() => setContractAddress(wallet.walletAddress)}
              >
                Load Account
              </Button>
            </Box>
          );
        })
      ) : (
        <Text>No wallets deployed yet.</Text>
      )}
    </Box>
  );
};

export default DeployedWallets;
