import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import JakobWallet from '../../contracts/JakobWallet.json';
import { Box, Button, Heading, Input, Text, VStack, Select } from '@chakra-ui/react';
import { AppContext } from '../../AppContext';

const TenetPluginManager = () => {
  const { rpcUrl, account, contractAddress } = useContext(AppContext);

  const [isApproved, setIsApproved] = useState(false);
  const [status, setStatus] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [selectedToken, setSelectedToken] = useState('USDC_ADDRESS'); // Default token selection
  const [tokenAddressMap, setTokenAddressMap] = useState({
    USDC_ADDRESS: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', // Replace with actual token addresses
    USDCE_ADDRESS: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    LINK_ADDRESS: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
    ETH_ADDRESS: '0x0000000000000000000000000000000000000000', // Native AVAX
    WETH_ADDRESS: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    WBTC_ADDRESS: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    BTC_ADDRESS: '0x152b9d0FdC40C096757F570A51E494bd4b943E50'
  });

  const contractABI = JakobWallet.abi;

  useEffect(() => {
    checkPluginApproval();
  }, []);

  const checkPluginApproval = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        contractAddress,
        JakobWallet.abi,
        provider
      );
      const positionRouterAddress = '0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868'; // Example address

      const isApproved = await contract.isPluginApproved(contractAddress, positionRouterAddress);
      console.log(`Plugin Approved`, isApproved);
      setIsApproved(isApproved);
    } catch (error) {
      console.error('Error checking Position Router plugin approval:', error);
    }
  };

  const handleApprovePlugin = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const tx = await contract.approvePositionRouter();
      setStatus('Approving Position Router as plugin...');
      await tx.wait();
      setStatus('Plugin Position Router approved successfully!');
    } catch (error) {
      console.error('Error approving plugin:', error);
      setStatus('Error approving plugin.');
    }
  };

  const handleApproveTokenSpending = async () => {
    if (!tokenAmount || isNaN(tokenAmount) || Number(tokenAmount) <= 0) {
      setStatus('Please enter a valid amount.');
      return;
    }

    try {
      const amountInWei = ethers.utils.parseUnits(tokenAmount, 6); // Assuming tokens have 6 decimals (adjust as needed)
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const tokenAddress = tokenAddressMap[selectedToken]; // Get the selected token address

      const tx = await contract.approveTokenSpending(tokenAddress, amountInWei);
      setStatus(`Approving ${selectedToken} spending...`);
      await tx.wait();
      setStatus(`${selectedToken} spending approved successfully!`);
    } catch (error) {
      console.error(`Error approving ${selectedToken} spending:`, error);
      setStatus(`Error approving ${selectedToken} spending.`);
    }
  };

  return (
    <Box margin="0 auto">
      

      <Box p={4} mb={2}>
      <Heading as="h3" size="md" marginTop="2px">
        Approve Token Spending
      </Heading>
      <Select
        value={selectedToken}
        onChange={(e) => setSelectedToken(e.target.value)}
        backgroundColor="white"
        marginBottom="10px"
      >
        {Object.keys(tokenAddressMap).map((tokenKey) => (
          <option key={tokenKey} value={tokenKey}>
            {tokenKey.replace('_ADDRESS', '')}
          </option>
        ))}
      </Select>
      <Input
        type="text"
        value={tokenAmount}
        onChange={(e) => setTokenAmount(e.target.value)}
        placeholder="Amount"
        backgroundColor="white"
      />
      <Button colorScheme="blue" width="100%" onClick={handleApproveTokenSpending}>
        Approve {selectedToken.replace('_ADDRESS', '')} Spending
      </Button>
      </Box>

      <Box p={4} bg='ghostwhite'>
        <Heading as="h3" size="md" marginTop="2px">
          Approve Exchange Router
        </Heading>

        <p>Plugin Approved: {isApproved ? 'Yes' : 'No'}</p>

        {!isApproved && (
          <>
            <Button colorScheme="teal" width="100%" onClick={handleApprovePlugin}>
              Approve Plugin
            </Button>
          </>
        )}
      </Box>
      {status && (
        <Text color={status.includes('Error') ? 'red.500' : 'green.500'} marginTop="20px">
          {status}
        </Text>
      )}
    </Box>
  );
};

export default TenetPluginManager;
