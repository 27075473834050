import { Box } from "@chakra-ui/react";
import BtcSignalsTable from "../blindfold-api/BtcSignalsTable";
import TradeSignalsTable from "../blindfold-api/TradeSignalsTable";
import OptimalTradeConfig from "../blindfold-api/OptimalTradeConfig";


const SignalsSection = () => {


return (
    <>
    <Box
      p={2}
      w="100%"
      border="0px solid gray"
      borderRadius={4}
    >
    <OptimalTradeConfig />
    <TradeSignalsTable />
    <BtcSignalsTable />
    
      
    </Box>
    </>
)

} 
export default SignalsSection;