import { Box, Flex, VStack, Stack, Image, Link } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const NavBar = ({ loading }) => {
  return (
    <Box bg="#2c2a2b" borderBottom='1px solid dodgerblue' p={1}>
      <Flex alignItems="center" justifyContent="space-between">
        <Link
          as="a"
          href="/"
          rel="noopener noreferrer"
          aria-label="Open Analytics"
          variant="outline"
          size="sm"
        >
          <Image
            src="/blindfold-logo-white.png"
            alt="Logo"
            maxW={{ base: "125px", md: "130px", lg: "140px" }} // Adjust max-width for different screen sizes
            h="auto" // Maintain aspect ratio
            objectFit="contain"
          />
        </Link>
        <Flex alignItems="center">
          <Stack direction="row" spacing={2} p={1}>
            <ConnectButton
              label="Connect"
              accountStatus="avatar"
              chainStatus="icon"
              showBalance={{
                smallScreen: false,
                largeScreen: true,
              }}
            />
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
