import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ABI from "./ABI.json"; // Ensure ABI is in the correct path
import { AppContext } from "../AppContext";

const CONTRACT_ADDRESS = ABI.address; // Replace with your deployed contract

const APIAccess = ({ wethPrice }) => {
  const { rpcUrl, account, chainId } = useContext(AppContext);
  const [usdCost, setUSDCost] = useState(null);
  const [signer, setSigner] = useState(null);

  const [days, setDays] = useState("");
  const [expiry, setExpiry] = useState(null);
  const [activeSubscribers, setActiveSubscribers] = useState([]);
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  const [contractBalance, setContractBalance] = useState(null);
  const toast = useToast();
  const [tokenBalances, setTokenBalances] = useState([]);

  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  // Initialize Signer
  const initializeSigner = async () => {
    if (!account) return;
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    setSigner(web3Provider.getSigner());
  };

  const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI.abi, provider);

  useEffect(() => {
    initializeSigner();
    
    fetchSubscriptionPrice();
    fetchContractBalance();
    fetchActiveSubscribers();
  }, []);

  // Fetch Subscription Price
  const fetchSubscriptionPrice = async () => {
    try {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        ABI.abi,
        provider,
      );
      const price = await contractInstance.pricePerDay();
      setSubscriptionPrice(ethers.utils.formatEther(price)); // Convert to ETH/AVAX/MATIC
    } catch (error) {
      console.error("Error fetching subscription price:", error);
    }
  };

  // Fetch Contract Balance
  const fetchContractBalance = async () => {
    try {
      if (!provider) return;
      const balance = await provider.getBalance(CONTRACT_ADDRESS);
      setContractBalance(ethers.utils.formatEther(balance));
    } catch (error) {
      console.error("Error fetching contract balance:", error);
    }
  };

  // Check Subscription Expiry
  const checkSubscription = async () => {
    if (!account) {
      setExpiry("Not connected to wallet");
      return;
    }
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI.abi, signer);
      const isSubscriber = await contract.isSubscriber(account);
     // const expiryDate = new Date(expiryTimestamp.toNumber() * 1000);
      setExpiry(isSubscriber);
     /**
      setExpiry(
        expiryTimestamp.toNumber() > Date.now() / 1000
          ? expiryDate.toLocaleString()
          : "Expired",
      );
      */
    } catch (error) {
      console.error("Error checking subscription:", error);
    }
  };

  // Buy API Access
  const buyAccess = async () => {
    if (!contract || !account || !days) {
      toast({
        title: "Transaction Error",
        description: "Wallet not connected or invalid input",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const totalCost = ethers.utils.parseEther(
        (subscriptionPrice * days).toString(),
      );

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, ABI.abi, signer);
      const tx = await contract.buyAccess(days, { value: totalCost });
      await tx.wait();

      toast({
        title: "Subscription Purchased",
        description: `You have added ${days} days of access.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      checkSubscription();
      fetchContractBalance();
    } catch (error) {
      console.error("Error buying access:", error);
      toast({
        title: "Transaction Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch Active Subscribers
  const fetchActiveSubscribers = async () => {
    try {
        
      const subscribers = await contract.getActiveSubscribers();
      setActiveSubscribers(subscribers);
    } catch (error) {
      console.error("Error fetching active subscribers:", error);
    }
  };

  const withdrawTokens = async () => {
    if (!contract) return;
    try {
      const tx = await contract.withdrawFunds();
      await tx.wait();

      toast({
        title: "Withdrawal Successful",
        description: `Funds withdrawn.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error withdrawing tokens:", error);
      toast({
        title: "Withdrawal Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack
      justify="center"
      borderWidth="1px"
      borderRadius="md"
      p={4}
      bg="gray.50"
      shadow="md"
      spacing={4}
      maxWidth="600px"
    >
      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Subscription Price:
        </Text>
        <Text>
          {subscriptionPrice
            ? `${subscriptionPrice} ETH | per day`
            : "Loading..."}
        </Text>
      </Box>

      <HStack>
        <Input
          maxWidth="150px"
          placeholder="Number of days"
          type="number"
          value={days}
          onChange={(e) => setDays(e.target.value)}
        />
        <Button colorScheme="green" onClick={buyAccess}>
          Buy Access
        </Button>
      </HStack>

      <Box>
        <Text fontSize="lg" fontWeight="bold">
          Subscription Status:
        </Text>
        <Text>{expiry ? expiry : "Checking..."}</Text>
      </Box>

      <Box p={4} borderWidth="1px" borderRadius="lg" w="100%">
        <Text fontSize="lg" fontWeight="bold">
          Active Subscribers
        </Text>
        {activeSubscribers.length > 0 ? (
          activeSubscribers.map((subscriber, index) => (
            <Text key={index}>{subscriber}</Text>
          ))
        ) : (
          <Text>No active subscribers</Text>
        )}
      </Box>

      <Box p={4} borderWidth="1px" borderRadius="lg" w="100%">
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            Contract Balance:
          </Text>
          <Text>
            {contractBalance ? `${contractBalance} ETH` : "Loading..."}
          </Text>
        </Box>
        <Text fontSize="lg" fontWeight="bold">
          Admin: Withdraw Funds
        </Text>
        <Button colorScheme="red" onClick={withdrawTokens}>
          Withdraw
        </Button>
      </Box>
    </VStack>
  );
};

export default APIAccess;
