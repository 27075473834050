import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spinner,
  Button,
  Flex,
  Text,
  useToast,
  useBreakpointValue,
  Stack,
  HStack,
  IconButton,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { ImNewTab } from "react-icons/im";


const TradeSignalsTable = () => {
  const [tradeSignals, setTradeSignals] = useState([]);
  const [filteredSignals, setFilteredSignals] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  /**
   * Fetch trade signals data
   */
  const fetchTradeSignals = async () => {
    setLoading(true);
    try {
      const apiUrl = "https://blindfoldcapital.com/api/ta/recent_trade_signals";
      const response = await axios.get(apiUrl);

      if (response.data && Array.isArray(response.data.data)) {
        const signals = response.data.data.map((entry) => ({
          interval: entry.interval,
          signal: entry.signal,
          direction: entry.direction,
          longLeverage: entry.long_leverage,
          shortLeverage: entry.short_leverage,
          rsi: entry.rsi,
          ema_fast: entry.ema_fast,
          ema_slow: entry.ema_slow,
          vwap: entry.vwap, // Added VWAP
          timestamp: entry.timestamp,
        }));

        const recentSignals = ["1m", "1h", "1d"]
          .map((interval) => {
            const intervalSignals = signals.filter(
              (signal) => signal.interval === interval
            );
            return intervalSignals.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            )[0];
          })
          .filter(Boolean);

        setTradeSignals(recentSignals);
        setFilteredSignals(recentSignals);
      } else {
        throw new Error("Invalid API response format");
      }
    } catch (error) {
      toast({
        title: "Error fetching trade signals",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Auto-refresh every 2 minutes
   */
  useEffect(() => {
    fetchTradeSignals();
    const interval = setInterval(fetchTradeSignals, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box p={4}>
      <Flex mb={4} justify="space-between" align="center">
        <Heading size="sm">
        <HStack><Image src="./weth.png" w={'25px'}/>
                <Text>ETH/USDT Signals</Text></HStack>
        </Heading>

        <HStack spacing={2}>
        <Tooltip label="Open Tab" aria-label="Open New Tab Tooltip">
      <IconButton
        as="a"
        href="/eth-signals"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open Analytics"
        icon={<ImNewTab />}
        variant="outline"
        size="sm"
      />
    </Tooltip>
          <Button colorScheme="blue" size="sm" onClick={fetchTradeSignals}>
            Refresh
          </Button>
        </HStack>
      </Flex>
      {loading ? (
        <Flex direction="column" align="center" justify="center">
          <Spinner size="lg" mb={2} />
          <Text>Refreshing data...</Text>
        </Flex>
      ) : (
        <>
          {isMobile ? (
            <Stack spacing={4}>
              {filteredSignals.map((signal, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  bg="gray.50"
                  shadow="md"
                >
                  <HStack justify="space-between">
                    <Text>
                      <strong>Interval:</strong> {signal.interval}
                    </Text>
                    <Text>
                      <strong>RSI:</strong> {signal.rsi?.toFixed(2) || "N/A"}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>
                      <strong>Signal:</strong> {signal.signal}
                    </Text>
                    <Text>
                      <strong>Direction:</strong> {signal.direction}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>
                      <strong>EMA Fast:</strong>{" "}
                      {signal.ema_fast?.toFixed(2) || "N/A"}
                    </Text>
                    <Text>
                      <strong>EMA Slow:</strong>{" "}
                      {signal.ema_slow?.toFixed(2) || "N/A"}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>
                      <strong>VWAP:</strong> {signal.vwap?.toFixed(2) || "N/A"}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text>
                      <strong>Long Leverage:</strong> {signal.longLeverage}
                    </Text>
                    <Text>
                      <strong>Short Leverage:</strong> {signal.shortLeverage}
                    </Text>
                  </HStack>
                  <Text>
                    <strong>Timestamp:</strong>{" "}
                    {new Date(signal.timestamp).toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </Text>
                </Box>
              ))}
            </Stack>
          ) : (
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th>Interval</Th>
                  <Th>Signal</Th>
                  <Th>Direction</Th>
                  <Th isNumeric>RSI</Th>
                  <Th isNumeric>EMA Fast</Th>
                  <Th isNumeric>EMA Slow</Th>
                  <Th isNumeric>VWAP</Th>
                  <Th isNumeric>Long/Short</Th>
                  <Th>Timestamp</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredSignals.map((signal, index) => (
                  <Tr key={index}>
                    <Td>{signal.interval}</Td>
                    <Td>{signal.signal}</Td>
                    <Td>{signal.direction}</Td>
                    <Td isNumeric>{signal.rsi?.toFixed(2) || "N/A"}</Td>
                    <Td isNumeric>${signal.ema_fast?.toFixed(2) || "N/A"}</Td>
                    <Td isNumeric>${signal.ema_slow?.toFixed(2) || "N/A"}</Td>
                    <Td isNumeric>${signal.vwap?.toFixed(2) || "N/A"}</Td>
                    <Td isNumeric>
                      {signal.longLeverage}/{signal.shortLeverage}
                    </Td>
                    <Td>
                      {new Date(signal.timestamp).toLocaleString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </>
      )}
    </Box>
  );
};

export default TradeSignalsTable;
