import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Box,
  Button,
  Input,
  FormLabel,
  Heading,
  VStack,
  Text,
  Select,
  HStack,
  Tooltip,
  Spacer,
} from "@chakra-ui/react";
import JakobFactory from "../../contracts/JakobFactory.json";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";
import { InfoIcon } from "@chakra-ui/icons";
import { formatAddress } from "../../utils/formatMetamask";
import CopyToClipboardButton from "../../utils/CopyToClipboardButton";

const CreateWallet = () => {
  const { rpcUrl, account, chainId } = useContext(AppContext);
  const [owner1, setOwner1] = useState("");
  const [owner2, setOwner2] = useState("");
  const [owner3, setOwner3] = useState("");
  const [paymaster, setPaymaster] = useState("");
  const [contractName, setContractName] = useState("");
  const [category, setCategory] = useState("COLLAR");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [estimatedCost, setEstimatedCost] = useState(null);
  const [estimatedCostUsd, setEstimatedCostUsd] = useState(null);
  const [wethPrice, setWethPrice] = useState(null);

  useEffect(() => {
    fetchWethPrice();
  }, []);

  // Function to create a new wallet
  const createWallet = async () => {
    if (
      !owner1 ||
      !owner2 ||
      !owner3 ||
      !paymaster ||
      !contractName ||
      !category
    ) {
      setMessage("Please fill out all fields");
      return;
    }

    try {
      setLoading(true);

      // Connect to MetaMask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []); // Ensure user connects wallet
      const signer = provider.getSigner();
      const userAddress = await signer.getAddress();
      console.log("Connected Wallet:", userAddress);

      // Load the contract with the signer
      const JakobFactoryContract = new ethers.Contract(
        JakobFactory.address,
        JakobFactory.abi,
        signer,
      );

      // Populate transaction data
      const txData =
        await JakobFactoryContract.populateTransaction.createWallet(
          owner1,
          owner2,
          owner3,
          paymaster,
          contractName,
          category,
        );

      console.log("Transaction Data:", txData);

      // Manually send transaction
      const txResponse = await signer.sendTransaction({
        to: JakobFactory.address,
        data: txData.data
      });

      await txResponse.wait();
      console.log("Transaction successful:", txResponse);

      setMessage("Account successfully created");
    } catch (error) {
      console.error("Error creating wallet:", error);
      setMessage("Error creating wallet.");
    } finally {
      setLoading(false);
    }
  };

  // Function to estimate the deployment cost
  const estimateDeploymentCost = async () => {
    if (
      !owner1 ||
      !owner2 ||
      !owner3 ||
      !paymaster ||
      !contractName ||
      !category
    ) {
      setMessage("Please fill out all fields to estimate cost.");
      return;
    }

    try {
      //const provider = new ethers.providers.JsonRpcProvider(rpcUrl); // Ensure Arbitrum RPC
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const senderAddress = await signer.getAddress(); // Ensure wallet connection

      const JakobFactoryContract = new ethers.Contract(
        JakobFactory.address,
        JakobFactory.abi,
        provider,
      );

      // Encode the transaction data
      const txData = JakobFactoryContract.interface.encodeFunctionData(
        "createWallet",
        [owner1, owner2, owner3, paymaster, contractName, category],
      );

      // Estimate gas for the transaction
      const gasEstimate = await provider.estimateGas({
        from: senderAddress,
        to: JakobFactory.address,
        data: txData,
      });

      console.log("Estimated Gas:", gasEstimate.toString());

      // Get Arbitrum gas price
      const gasPrice = await provider.getGasPrice();

      console.log(
        "Gas Price:",
        ethers.utils.formatUnits(gasPrice, "gwei"),
        "Gwei",
      );

      // Calculate total estimated cost
      const estimatedCostInWei = gasEstimate.mul(gasPrice);
      console.log(`Estimated Cost (WEI)`, estimatedCostInWei);
      const estimatedCostInEth = ethers.utils.formatEther(estimatedCostInWei);
      console.log(`Estimated Cost (ETHER)`, estimatedCostInEth);
      setEstimatedCost(estimatedCostInEth);

      const price = await fetchWethPrice();
      const costInUsd = (parseFloat(estimatedCostInEth) * price).toFixed(2);
      setEstimatedCostUsd(costInUsd);
      console.log(`Estimated Cost (USD)`, costInUsd);
    } catch (error) {
      console.error("Error estimating gas:", error);
      setMessage("Error estimating deployment cost.");
    }
  };

  const fetchWethPrice = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const weth = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider,
      );
      const acceptablePrice = await routerContract.getPriceV1(
        weth,
        false,
        true,
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      const price = priceInWei.div(scaleFactor).toNumber() / 1000;
      console.log(`WETH Price`, wethPrice);
      setWethPrice(price);
      return price;
    } catch (error) {
      console.error("Error fetching WAVAX price:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (owner1 && owner2 && owner3 && paymaster && contractName && category) {
      estimateDeploymentCost();
    }
  }, [owner1, owner2, owner3, paymaster, contractName, category]);

  return (
    <Box shadow="md" bg='white' borderWidth="1px" borderRadius="sm" p={1}>
      <HStack w="100%" borderBottom="1px solid silver" p={1} wrap="wrap">
        {/* Left-aligned network information */}
        <Text fontSize="sm" textAlign="left">
          Network: Arbitrum
        </Text>

        <Spacer />

        {/* Right-aligned factory address */}
        <HStack spacing={2} justify="right">
          <a
            href={`https://arbiscan.io/address/${JakobFactory.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text fontSize="sm" textAlign="right">
              Factory: {formatAddress(JakobFactory.address)}
            </Text>
          </a>
          <CopyToClipboardButton value={JakobFactory.address} />
        </HStack>
      </HStack>
      <Heading as="h2" size="md" mb={2} color="blue.600">
        Create account
      </Heading>
      <Box p={4} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
        <Text fontSize="sm" fontWeight="bold">
          Connected:{" "}
          <Text as="span" color={account ? "green.500" : "red.500"}>
            {formatAddress(account) || "Not connected"}
          </Text>
        </Text>
        <HStack>
          <Text fontSize="sm">
            <b>Chain ID:</b> {chainId}
          </Text>
          {chainId === 42161}
          {
            <>
              <Text fontSize="sm">Arbitrum</Text>
            </>
          }
        </HStack>
        <Text fontSize="sm" isTruncated>
          <b>RPC URL:</b> {rpcUrl}
        </Text>
      </Box>

      <VStack p={4} spacing={2} align="stretch">
        <Box>
          <FormLabel>Name</FormLabel>
          <Input
            size="sm"
            bg="white"
            placeholder="Name"
            value={contractName}
            onChange={(e) => {
              const input = e.target.value;
              if (input.includes(" ")) {
                setMessage("Spaces are not allowed in the account name.");
              } else {
                setMessage(""); // Clear the message when the input is valid
              }
              setContractName(input.replace(/\s+/g, "")); // Remove spaces
            }}
          />
        </Box>

        <Box>
          <HStack spacing={1}>
            <FormLabel>Strategy</FormLabel>
            <Tooltip
              label={
                <>
                  <Box p={2}>
                    COLLAR (WAVAX/WETH/WBTC/USDC.e): Collar is a hedging
                    strategy that maintains a continuous long & short position.{" "}
                    <br />
                    <br />
                    DCA (WAVAX/WETH/WBTC/USDC.e): DCA strategy buys more of an
                    asset at regular intervals or when the price falls, lowering
                    the average entry price. <br />
                    <br />
                    TREND (WAVAX/WETH/WBTC/USDC.e): Momentum strategy buys or
                    sells based on the strength and direction of market trends.{" "}
                    <br />
                    <br />
                  </Box>
                </>
              }
              fontSize="xs"
              placement="right"
            >
              <InfoIcon boxSize={4} mb={2} color="gray.400" cursor="pointer" />
            </Tooltip>
          </HStack>
          <Select
            size="sm"
            bg="white"
            placeholder="Select type"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="COLLAR">COLLAR (WAVAX/WETH/WBTC/USDC.e)</option>
            <option value="DCA">DCA (WAVAX/WETH/WBTC/USDC.e)</option>
            <option value="TREND">TREND (WAVAX/WETH/WBTC/USDC.e)</option>
            {/* 
            <option value="SHORT">SHORT</option>
            <option value="LONG">LONG</option> */}
          </Select>
        </Box>

        <Box>
          <FormLabel>Owner 1 Address</FormLabel>
          <Input
            bg="white"
            size="sm"
            placeholder="Enter Owner 1 Address"
            value={owner1}
            onChange={(e) => setOwner1(e.target.value)}
          />
        </Box>

        <Box>
          <FormLabel>Owner 2 Address</FormLabel>
          <Input
            size="sm"
            bg="white"
            placeholder="Enter Owner 2 Address"
            value={owner2}
            onChange={(e) => setOwner2(e.target.value)}
          />
        </Box>

        <Box>
          <FormLabel>Owner 3 Address</FormLabel>
          <Input
            bg="white"
            size="sm"
            placeholder="Enter Owner 3 Address"
            value={owner3}
            onChange={(e) => setOwner3(e.target.value)}
          />
        </Box>

        <Box>
          <HStack spacing={1}>
            <FormLabel>Paymaster</FormLabel>
            <Tooltip
              label="DO NOT CREATE AN ACCOUNT IF YOU HAVE NOT BEEN GIVEN A PAYMASTER ADDRESS!"
              fontSize="xs"
              placement="right"
            >
              <InfoIcon boxSize={4} mb={2} color="gray.400" cursor="pointer" />
            </Tooltip>
          </HStack>
          <Input
            size="sm"
            bg="white"
            placeholder="Enter Paymaster Address"
            value={paymaster}
            onChange={(e) => setPaymaster(e.target.value)}
          />
        </Box>

        {estimatedCost && estimatedCostUsd && !message && (
          <Text fontSize="sm" color="green">
            Estimated Deployment Cost: {parseFloat(estimatedCost).toFixed(3)}{" "}
            AVAX | ${estimatedCostUsd}
          </Text>
        )}

        {message && <Text color="green">{message}</Text>}
        {/**<Box p={3} fontSize='xs' overflow='hidden'>
          <Text>Name:{contractName}</Text>
          <Text>Category:{category}</Text>
          <Text>Owner1:{formatAddress(owner1)}</Text>
          <Text>Owner2:{formatAddress(owner2)}</Text>
          <Text>Owner3:{formatAddress(owner3)}</Text>
          <Text>Paymaster:{formatAddress(paymaster)}</Text>
        </Box> */}
        <HStack spacing={4} mt={3} mb={3}>
          <Button
            size="sm"
            colorScheme="blackAlpha"
            onClick={() => {
              setOwner1(account); // Reset owner1 input
              setOwner2(""); // Reset owner2 input
              setOwner3(""); // Reset owner3 input
              setContractName(""); // Reset contract name input
              setCategory(""); // Reset category selection
              setEstimatedCost(null); // Reset estimated cost
              setEstimatedCostUsd(null); // Reset estimated USD cost
              setMessage(""); // Reset any messages
            }}
          >
            Reset
          </Button>

          <Button
            w={"100%"}
            size="sm"
            colorScheme="blue"
            onClick={createWallet}
            isLoading={loading}
            loadingText="Creating..."
          >
            Create Account
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default CreateWallet;
