function VideoPlayer() {
    return (
      <div style={styles.container}>
        <video 
          src="/blindfold-logo.mp4" 
          autoPlay 
          loop 
          muted 
          playsInline 
          style={styles.video} 
        />
      </div>
    );
  }
  
  const styles = {
    container: {
      width: '100%',
      maxWidth: '700px', // Optional: Adjust max width for better scaling
      aspectRatio: '16/9', // Ensures correct scaling for different screen sizes
      backgroundColor: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover', // Ensures the video fills the container
      borderRadius: '8px', // Optional: Adds smooth edges
    }
  };
  
  export default VideoPlayer;
  