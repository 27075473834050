import React from 'react';
import { Box, Heading, Text, Link, Stack, IconButton, useToast, Flex } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const ContractList = () => {
  const toast = useToast();

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    toast({
      title: "Copied to clipboard",
      description: `${address}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const contracts = [
    { name: "Blindfold Wallet Factory", address: "0x61b4CdDEF175375b1A582A1c9d7cD3c89EF7F21E" },
    { name: "Position Router", address: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868" },
    { name: "Router", address: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064" },
    { name: "Order Book", address: "0x09f77e8a13de9a35a7231028187e9fd5db8a2acb" },
    { name: "Reader", address: "0x67b789D48c926006F5132BFCe4e976F0A7A63d5D" },
    { name: "Vault", address: "0x489ee077994B6658eAfA855C308275EAd8097C4A" },
    { name: "Vault Price Feed", address: "0x2d68011bcA022ed0E474264145F46CC4de96a002" },
  ];

  return (
    <Box
      p={{ base: 1, md: 2 }}
      maxW="container.md"
      mx="auto"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      bg="white"
    >
      <Heading size='md' textAlign="center" mb={4}>
        Contracts
      </Heading>

      <Stack spacing={2}>
        {contracts.map((contract, index) => (
          <Flex
            key={index}
            direction={{ base: "column", sm: "row" }}
            align="center"
            p={1}
            borderWidth="1px"
            borderRadius="md"
            bg="gray.50"
            justify="space-between"
            gap={2} // Adds spacing between elements
          >
            {/* Contract Name Column */}
            <Box flex={{ base: "1", sm: "0.3" }} textAlign={{ base: "center", sm: "left" }}>
              <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                {contract.name}:
              </Text>
            </Box>

            {/* Contract Address Column */}
            <Box
              flex="1"
              wordBreak="break-all"
              textAlign={{ base: "center", sm: "left" }}
              fontFamily="monospace"
              fontSize={{ base: "sm", md: "md" }}
            >
              <Text>{contract.address}</Text>
            </Box>

            {/* Copy Button Column */}
            <Box flex={{ base: "none", sm: "0.1" }} textAlign="right">
              <IconButton
                icon={<CopyIcon />}
                size="sm"
                onClick={() => handleCopy(contract.address)}
                aria-label={`Copy ${contract.name}`}
              />
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default ContractList;
