import { AppContext } from "./AppContext";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";

import {
  Box,
  TabPanel,
  TabPanels,
  Tabs,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  Tab,
  TabList,
  Wrap,
  WrapItem,
  Image,
  Center,
} from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import { BsCurrencyExchange } from "react-icons/bs";
import { FiHome, FiBook } from "react-icons/fi";
import { IoMdAnalytics } from "react-icons/io";
import VaultPriceFeed from "./contracts/VaultPriceFeed.json";
import FooterMain from "./components/FooterMain";
import JakobWallet from "./contracts/JakobWallet.json";
import TenetBox from "./components/wallet/TenetBox";
import GmxPositions from "./components/gmx/GmxPositions";
import WalletTabs from "./components/wallet/WalletTabs";
import ActiveContractInfo from "./components/ActiveContractInfo";
import BlindfoldInfo from "./components/documentation/BlindfoldInfo";
import ContractList from "./components/documentation/ContractList";
import FetchBtcPriceBase from "./components/prices/FetchBtcPriceBase";
import FetchWethPriceBase from "./components/prices/FetchWethPriceBase";
import DirectionDisplay from "./blindfold-api/DirectionDisplay";
import TradeSignalsTable from "./blindfold-api/TradeSignalsTable";
import BtcSignalsTable from "./blindfold-api/BtcSignalsTable";
import IndicatorDescription from "./blindfold-api/IndicatorDescription";
import SignalsSection from "./components/SignalsSection";
import FetchLinkPriceBase from "./components/prices/FetchLinkPriceBase";
import OptimalTradeConfigDescription from "./blindfold-api/OptimalTradeConfigDescription";
import BitcoinTrendIndicators from "./blindfold-api/BitcoinIndicatorDescription";
import APIAccess from "./blindfold-api/APIAccess";


function App() {
  const tabOrientation = "vertical"; // Always vertical

  // RPC Connections
// RPC Connections
const { address, isConnected } = useAccount();
const { chain } = useNetwork();


  const rpc42161 = "https://arb1.arbitrum.io/rpc";
  const [rpcUrl, setRpcUrl] = useState(rpc42161);
  const [chainId, setChainId] = useState(42161);
  const [account, setAccount] = useState("");
  const [contractAddress, setContractAddress] = useState(JakobWallet.address);
  const walletABI = JakobWallet.abi;
  const [contractName, setContractName] = useState("");
  useEffect(() => {
    if (isConnected && 42161) {
      console.log(`Connected to chain ID: ${chain.id}`);

      // ✅ Update chainId & RPC URL
      setChainId(42161);
      setRpcUrl(rpc42161); // Default to Avalanche if unknown chain

      // ✅ Set wallet address
      setAccount(address);
    }
  }, [isConnected, chain, address]);
  // State Variables
  const [tradeSettings, setTradeSettings] = useState(null);
  const [accountBalance, setAccountBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [totalPositions, setTotalPositions] = useState(0);
  const [btcPrice, setBtcPrice] = useState(null);
  const [linkPrice, setLinkPrice] = useState(null);
  const [wethPrice, setWethPrice] = useState(null);

  // Callback function to update the BTC price
  const handleBtcPriceUpdate = (newBtcPrice) => {
    setBtcPrice(newBtcPrice);
  };

  // Callback function to update the link price
  const handleLinkPriceUpdate = (newLinkPrice) => {
    setLinkPrice(newLinkPrice);
  };

  const handleWethPriceUpdate = (newWethPrice) => {
    setWethPrice(newWethPrice);
  };

  const handleTotalCollateralChange = (collateral) => {
    setTotalCollateral(parseFloat(collateral) || 0);
  };

  const handleTotalPositionsChange = (totalPositions) => {
    setTotalPositions(parseFloat(totalPositions) || 0);
  };

  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const [currentPrice, setCurrentPrice] = useState("");
  
  const fetchPrice = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://arb1.arbitrum.io/rpc",
      );
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider,
      );
      const tx = await routerContract.getPriceV1(
        "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        false,
        true,
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(tx);
      const price = priceInWei.div(scaleFactor);
      const adjusted = Number(price.toString()) / 1000;
      setCurrentPrice(adjusted);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch contract name
  const fetchContractName = async () => {
    if (!contractAddress) return; // Skip if no contractAddress
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        contractAddress,
        walletABI,
        provider,
      );
      const name = await contract.ContractName();
      setContractName(name);
    } catch (error) {
      console.error("Error fetching contract info:", error);
    }
  };

  useEffect(() => {
    fetchPrice();
    if (contractAddress) {
      fetchContractName();
    }
  }, [contractAddress]);

  // Callback to handle the trade settings data
  const handleDataFetched = (settings) => {
    console.log("Trade settings fetched:", settings);
    setTradeSettings(settings); // Set the fetched data in the state
  };

  const [totalBalance, setTotalBalance] = useState(0);

  const updateTotalBalance = (balance) => {
    setTotalBalance(balance);
  };

  const [activeBalance, setActiveBalance] = useState(0);

  const handleActiveBalanceUpdate = (balance) => {
    setActiveBalance(balance);
  };

  return (
    <AppContext.Provider
      value={{
        account,
        setAccount,
        chainId,
        setChainId,
        accountBalance,
        setAccountBalance,
        walletABI,
        rpcUrl,
        rpc42161,
        provider,
        contractAddress,
        setContractAddress,
        contractName,
        setContractName,
      }}
    >
      <Router>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
          }}
        >
          <div style={{ padding: 0, flexDirection: "row" }}>
            <NavBar loading={isLoading} />
          </div>

          <Routes>
            <Route
              path="/"
              element={
                <Box
                  height="100%"
                  minHeight="100vh"
                  w="auto"
               //   bg="#2c2a2b"
                  fontFamily="Sentinel"
                  //justify="center"
                >
               
                  <HStack bg="#2c2a2b" color="white">
                    <HStack p={1} gap="2px">
                      <Image src={"./btc.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${btcPrice}
                      </Text>
                    </HStack>

                    <HStack p={1} gap="2px">
                      <Image src={"./chainlink-icon.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${linkPrice}
                      </Text>
                    </HStack>

                    <HStack p={1} gap="2px">
                      <Image src={"./eth.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${wethPrice}
                      </Text>
                    </HStack>

                    <HStack gap="auto" overflow="hidden">
                      <DirectionDisplay />

                      {/**<SignalDisplay /> */}
                    </HStack>
                  </HStack>

                  <Tabs
                    isLazy
                    bg="white"
                    p={0}
                    //w="100%"
                    variant="enclosed"
                    h="100%"
                    //minHeight="600px"
                    //      h="calc(100vh)" // Adjust for header/footer height
                    //display="flex"
                    //flexDirection="row"
                    orientation={tabOrientation}
                  >
                    <TabList
                      width="60px" // Narrow width for icons only
                      bg="#2c2a2b"
                      p={0}
                      //shadow="md"
                      color="white"
                      // borderWidth="1px"
                      border="0px solid #2c2a2b "
                      gap={8}
                    >
                      <TabItem icon={FiHome} label="Home" />
                      <TabItem icon={BsCurrencyExchange} label="Positions" />
                      {/**<TabItem icon={FiDatabase} label="Data" /> */}
                      <TabItem icon={IoMdAnalytics} label="Signals" />
                      <TabItem icon={FiBook} label="Docs" />
                    </TabList>

                    <TabPanels shadow='md'>
                      <TabPanel p={0}>
                        {/* Home Tab Content */}
                        {contractAddress && (
                          <HStack
                            justify="left"
                            w="100%"
                            //p={0}
                            h="28px"
                            ml={1}
                            //shadow="md"
                            //borderBottom="1px solid gray.100"
                          >
                            {contractAddress && <ActiveContractInfo />}
                          </HStack>
                        )}
                        <WalletTabs />
                      </TabPanel>
                      <TabPanel p={0}>
                        {/* Positions Content */}
                        <HStack
                          justify="left"
                          //p={1}
                          pl={1}
                          shadow="md"
                          borderBottom="1px solid gray.100"
                        >
                          {contractAddress && <ActiveContractInfo />}
                        </HStack>
                        <Wrap w="100%" h="100%">
                          <WrapItem w="100%" minWidth="300px">
                            <TenetBox
                              totalCollateral={totalCollateral}
                              totalPositions={totalPositions}
                              handleDataFetched={handleDataFetched}
                              activeBalance={activeBalance}
                            />
                          </WrapItem>
                          <WrapItem w="100%">
                            <GmxPositions
                              onTotalCollateralChange={
                                handleTotalCollateralChange
                              }
                              onTotalPositionChange={handleTotalPositionsChange}
                              contractAddress={contractAddress}
                              currentPrice={currentPrice}
                              fetchPrice={fetchPrice}
                              tradeSettings={tradeSettings}
                              handleActiveBalanceUpdate={
                                handleActiveBalanceUpdate
                              }
                            />
                          </WrapItem>
                        </Wrap>
                        <SignalsSection/>
                      </TabPanel>
                     {/** <TabPanel flex="1" display="flex" flexDirection="column">
                        
                        <DataTabs
                          currentPrice={currentPrice}
                          totalCollateral={totalCollateral}
                        />
                      </TabPanel>
                       */}
                      <TabPanel>
                    {/**<SignalsSection/> */}
                    {/**<Center>
                    <APIAccess wethPrice={wethPrice}/>
                    </Center> */}

                    <OptimalTradeConfigDescription/>

                    <BitcoinTrendIndicators/>
                    
                      </TabPanel>
                      <TabPanel>
                        {/**Help Content */}
                        
                          <Tabs
                            variant="enclosed"
                            colorScheme="blue"
                            bg="white"
                            //p={1}
                          >
                            <TabList>
                              <Tab>Blindfold</Tab>
                              <Tab>Contracts</Tab>
                            </TabList>
                            <TabPanels>
                              <TabPanel p={1}>
                                <BlindfoldInfo />
                              </TabPanel>
                              <TabPanel p={1}>
                                <ContractList />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <FetchBtcPriceBase onBtcPriceUpdate={handleBtcPriceUpdate} />
                 <FetchLinkPriceBase onLinkPriceUpdate={handleLinkPriceUpdate}/>
                  <FetchWethPriceBase
                    onWethPriceUpdate={handleWethPriceUpdate}
                  />
                </Box>
              }
            />
         

            <Route
              path="/signals"
              element={
                <>
                  <Text>Blindfold Composite Signals</Text>
                  <SignalsSection/>
                  <IndicatorDescription />
                  {/**<TradeSignalsTableWithFilter/> */}
                </>
              }
            />

            <Route
              path="/eth-signals"
              element={
                <>
                  {/** LINK Indicator table */}
                  <Box p={2} mb={4}>
                    <TradeSignalsTable />

                 
               
                    <IndicatorDescription />
                  </Box>
                  {/**<TradeSignalsTableWithFilter/> */}
                </>
              }
            />

            <Route
              path="/btc-signals"
              element={
                <>
                  <Text>Blindfold Composite Signals</Text>
                  <BtcSignalsTable />
                  <IndicatorDescription />
                  
                  {/**<TradeSignalsTableWithFilter/> */}
                </>
              }
            />
          </Routes>
          <FooterMain
            style={{ flexShrink: 0, position: "sticky", bottom: 0 }}
          />
        </div>
      </Router>
    </AppContext.Provider>
  );

  function TabItem({ icon: IconComponent, label }) {
    return (
      <Popover trigger="hover" placement="right">
        <PopoverTrigger>
          <Tab>
            <IconComponent size={24} />
          </Tab>
        </PopoverTrigger>
        <PopoverContent color="black" width="fit-content" boxShadow="dark-lg">
          <PopoverBody display="flex" alignItems="center">
            <IconComponent size={24} style={{ marginRight: "4px" }} />
            {label}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
}

export default App;
