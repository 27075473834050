import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Card,
  CardHeader,
  CardBody,
  List,
  ListItem,
  ListIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";


const BitcoinTrendIndicators = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://blindfoldcapital.com/api/ta/recent_btc_signals");
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Box maxW="800px" mx="auto" mt={5}>
      <Card borderRadius="2xl" boxShadow="lg" p={5}>
        <CardHeader>
          <Text fontSize="2xl" fontWeight="bold">
            Bitcoin Trend & Indicators API
          </Text>
        </CardHeader>
        <CardBody>
          <Text fontSize="md" mb={4}>
            The **Bitcoin Trend & Indicators API** provides real-time trading insights, helping traders make 
            informed decisions by analyzing **Relative Strength Index (RSI), Exponential Moving Averages (EMA), 
            Volume Weighted Average Price (VWAP), and trade signals**. The API dynamically generates insights 
            for different time intervals to improve trading strategies.
          </Text>
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            Key Features:
          </Text>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Multi-Timeframe Analysis** – Provides insights for 1-minute, 1-hour, and 1-day intervals.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Trend Identification** – Detects **Uptrend, Sideways, and Downtrend** movements.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Trade Signals** – Suggests **Buy, Sell, or Neutral** positions.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              **Leverage Recommendations** – Provides **optimized long and short leverage** for each trend.
            </ListItem>
          </List>

              {/* API Data Table */}
              <Text fontSize="lg" fontWeight="semibold" mt={6} mb={3}>
            Latest Bitcoin Trend Indicators:
          </Text>
          {loading ? (
            <Spinner size="lg" />
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Timestamp</Th>
                    <Th>Interval</Th>
                    <Th>RSI</Th>
                    <Th>EMA Fast</Th>
                    <Th>EMA Slow</Th>
                    <Th>VWAP</Th>
                    <Th>Signal</Th>
                    <Th>Direction</Th>
                    <Th>Long Lev</Th>
                    <Th>Short Lev</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.timestamp}</Td>
                      <Td>{item.interval}</Td>
                      <Td>{item.rsi.toFixed(2)}</Td>
                      <Td>{item.ema_fast.toFixed(2)}</Td>
                      <Td>{item.ema_slow.toFixed(2)}</Td>
                      <Td>{item.vwap.toFixed(2)}</Td>
                      <Td>{item.signal}</Td>
                      <Td>{item.direction}</Td>
                      <Td>{item.long_leverage}x</Td>
                      <Td>{item.short_leverage}x</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export default BitcoinTrendIndicators;
